// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1733459677541.6008";
}
// REMIX HMR END

import { Center, Container, Input, Stack, VStack } from "@chakra-ui/react";
import { json, redirect } from "@remix-run/node";
import { Form, useActionData } from "@remix-run/react";
import { useEffect, useState } from "react";
import TruthistHeader from "~/components/truthist-header";
import TruthistPrimaryButton from "~/components/truthist-primary-button";
import { TruthistB2, TruthistB6 } from "~/components/truthist-styles";
import { createCharacter, getCharacterByTag } from "~/utils/characters.server";
import { getCharacter } from "~/utils/auth.server";
import { validateTag } from "~/utils/validators.server";
export const loader = async ({
  request
}) => {
  return (await getCharacter(request)) ? redirect("/contexts") : null;
};
export const action = async ({
  request
}) => {
  const form = await request.formData();
  const tag = form.get("tag");
  if (typeof tag !== "string") {
    return json({
      error: `Invalid Form Data`,
      form: action
    }, {
      status: 400
    });
  }
  let error = validateTag(tag);
  if (error) return json({
    error
  }, {
    status: 400
  });
  let character = await getCharacterByTag(tag);
  if (!character) character = await createCharacter(tag);
  return redirect(`/login/${character.oId}`);
};
export default function Login() {
  _s();
  const actionData = useActionData();
  const [error, setError] = useState("");
  useEffect(() => {
    setError(actionData?.error || "");
  }, [actionData]);
  return <>
      <TruthistHeader />
      <Container maxW={"container.xl"} pb={8}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6} align={"center"}>
          <Form method="POST">
            <VStack spacing={4}>
              <Center>
                <TruthistB2 mt="12" align={"center"}>
                  Please enter tag to get started
                </TruthistB2>
              </Center>
              <Input type={"text"} name={"tag"} placeholder="tag" autoFocus={true} />
              <TruthistB6 color={"red.700"}>{error}</TruthistB6>
              <TruthistPrimaryButton type="submit" name="_action" value={action}>
                Submit
              </TruthistPrimaryButton>
            </VStack>
          </Form>
        </Stack>
      </Container>
    </>;
}
_s(Login, "0pcMmg6k+5VoV7Qv/5VljSN5RzA=", false, function () {
  return [useActionData];
});
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;